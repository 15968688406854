export default {
  heading: 'Merriweather, serif',
  body: 'Merriweather Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Merriweather, serif',
  googleFonts: [
    'Merriweather:100,200,300,400,500,600,700,800,900',
    'Merriweather Sans:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
